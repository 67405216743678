<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px;">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>新建文章</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建文章</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="文章名">-->
      <!--                            <a-select-option value="username">文章名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">文章名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row>
        <a-col :push="0" :span="24">
          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">

            <!--                                {{data}}-->
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 5 }" label="Category">
              <!--        {{ treeData }}-->
              <!--        {{ Category_pid }}-->
              <a-tree-select
                  v-model="category_id"
                  :dropdown-style="{ maxHeight: '600px', overflow: 'auto' }"
                  :replaceFields="replaceFields"
                  :tree-data="treeData"
                  placeholder="Please select"
                  allow-clear
                  tree-default-expand-all
                  style="width: 100%"
                  @change="onselectpid"
              >
          <span slot="title" slot-scope="{ key, value }">
            {{ value }}
          </span>
              </a-tree-select>
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="language">
              <a-radio-group v-model="add_article.language" button-style="solid" @change="Changelanguage">
                <a-radio-button value="cn">
                  Cn
                </a-radio-button>
                <a-radio-button value="en">
                  En
                </a-radio-button>
                <a-radio-button value="fr">
                  Fr
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="文章标题">
              <a-input v-model="add_article.title"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="收费类型">
              <a-radio-group v-model="add_article.type" button-style="solid" @change="Changetype">
                <a-radio-button value="1">
                  免费
                </a-radio-button>
                <a-radio-button value="3">
                  会员（vip）可见
                </a-radio-button>
                <a-radio-button value="2">
                  会员（vip）需要另外付费观看
                </a-radio-button>

                <a-radio-button value="4">
                  线下课程-需额外付费
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item v-if="add_article.type === '4'" :label-col="{ span: 2 }" :wrapper-col="{ span: 18 }" label="绑定课程">
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-input v-model="add_article.course_title" disabled
                           allow-clear @change="onClealcourse"/>
                </a-col>
                <a-col :span="2">
                  <a-button @click="delClealcourse" v-show="add_article.course_id">
                    剔除课程
                  </a-button>
                  <a-button type="primary" @click="showcourseDrawer"  v-show="!add_article.course_id">
                    选择课程
                  </a-button>
                </a-col>
              </a-row>


            </a-form-item>
<!--            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 5 }" label="课程列表" v-if="add_article.type=== '4'">-->
<!--              &lt;!&ndash;        {{ treeData }}&ndash;&gt;-->
<!--              &lt;!&ndash;        {{ Category_pid }}&ndash;&gt;-->
<!--              <a-tree-select-->
<!--                  v-model="Class_id"-->
<!--                  :dropdown-style="{ maxHeight: '600px', overflow: 'auto' }"-->
<!--                  :replaceFields="replaceFields"-->
<!--                  :tree-data="ClasstreeData"-->
<!--                  placeholder="Please select"-->
<!--                  allow-clear-->
<!--                  tree-default-expand-all-->
<!--                  style="width: 100%"-->
<!--                  @change="onselectClass_id"-->
<!--              >-->
<!--          <span slot="title" slot-scope="{ key, value }">-->
<!--            {{ value }}-->
<!--          </span>-->
<!--              </a-tree-select>-->
<!--            </a-form-item>-->
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 5 }" label="收费金额"
                         help="最小0 最大10000"
            >
              <a-input-number
                  :min="0"
                  :default-value="0"
                  :max="10000"
                  :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\￥\s?|(,*)/g, '')"
                  :step="0.1"
                  :precision="2"
                  v-model="add_article.price"
                  style="width: 100%"
              />
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 5 }" label="作者">
              <a-input v-model="add_article.author"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 18 }" label="视频链接地址" :help="add_article.medialink">
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-input v-model="mediatitle" disabled
                           allow-clear @change="onCleal"/>
                </a-col>
                <a-col :span="2">
                  <a-button @click="delCleal" v-show="add_article.medialink">
                    剔除视频
                  </a-button>
                  <a-button type="primary" @click="showDrawer"  v-show="!add_article.medialink">
                    选择视频
                  </a-button>
                </a-col>
              </a-row>


            </a-form-item>

            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="文章封面图">
              <image-cropper
                  :options="cropperOptions"
                  :imgSize="2"
                  :imgType="imgType"
                  :imageUrl="imgUrl"
                  @crop-upload-close="cropClose"
                  @crop-upload-success="cropSuccess"
              />
<!--              <a-upload-->
<!--                  :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/images'"-->
<!--                  :file-list="fileList"-->
<!--                  class="ant-uploads"-->
<!--                  list-type="picture-card"-->
<!--                  @change="handleChange"-->
<!--                  @preview="handlePreview"-->
<!--              >-->
<!--                <div v-if="fileList.length < 1">-->
<!--                  <a-icon type="plus"/>-->
<!--                  <div class="ant-upload-text">-->
<!--                    upload-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a-upload>-->
              <div class="note">
                * 图片尺寸:266*280<br/>
                * 图片大小不超过2M<br/>
                * 图片类型：jpg或png<br/>
              </div>
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="文章内容">
              <a-upload
                  :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/images'"
                  :before-upload="beforeUploaditem"
                  :show-upload-list="false"
                  @change="handleChangeItem"
                  list-type="picture"
                  v-show="false"
              >
                <a-button>
                  <a-icon type="upload"/>
                  upload
                </a-button>
              </a-upload>
              <quill-editor
                  ref="myQuillEditor"
                  v-model="add_article.content"
                  :options="editorOption"
                  style="height: 550px;width: 100%;margin-bottom: 50px"
                  @change="onEditorChange($event)"

              >
              </quill-editor>
            </a-form-item>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22, offset: 2 }">
              <a-button type="primary" html-type="submit"  @click="article_addok">
                Submit
              </a-button>
            </a-form-item>
          </div>
        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-drawer
        title="视频选择"
        placement="right"
        width="60%"
        :closable="false"
        :visible="mediavisible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
    >
      <a-table
          bordered
          :columns="columns"
          :dataSource="videodata"
          :pagination="pagination"
          :loading="loading"
          @change="onChange"
          rowKey="uid"
      >
        <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="selectmedia(index)">选择</a>
                            </span>
        </template>

      </a-table>
    </a-drawer>
    <a-drawer
        title="课程选择"
        placement="right"
        width="60%"
        :closable="false"
        :visible="coursevisible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
    >
      <a-table
          bordered
          :columns="coursecolumns"
          :dataSource="coursedata"
          :pagination="coursepagination"
          :loading="loading"
          @change="oncourseChange"
          rowKey="uid"
      >
        <template slot="status" slot-scope="text, index" style="text-align: center">
          <a-tag v-if="index.status === 1" :color="'green'"><span>报名中</span></a-tag>
          <a-tag v-if="index.status === 2" :color="'red'"><span>进行中</span></a-tag>
          <a-tag v-if="index.status === 3" :color="'green'"><span>已完结</span></a-tag>
          <a-tag v-if="index.status === 4" :color="'green'"><span>已颁发</span></a-tag>
          <a-tag v-if="index.status === 5" :color="'green'"><span>已剔除</span></a-tag>
          <a-tag v-if="index.status === -1" :color="'red'">待发布</a-tag>
        </template>
        <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="selectcourse(index)">选择</a>
                            </span>
        </template>

      </a-table>
    </a-drawer>
  </a-layout>

</template>

<script>
// const treeData = [
//   {
//     title: '分类一',
//     key: '0',
//     children: [
//       {
//         title: '分类一一',
//         key: '00',
//         children: [
//           {title: '分类一一一', key: '001'},
//           {title: '分类一一二', key: '002'},
//           {title: '分类一一三', key: '003'},
//         ],
//       },
//       {
//         title: '分类一二',
//         key: '01',
//         children: [
//           {title: '分类一二一', key: '011'},
//           {title: '分类一二二', key: '012'},
//           {title: '分类一二三', key: '013'},
//         ],
//       },
//     ],
//   },
// ];
// import moment from "moment";
import {quillEditor} from "vue-quill-editor";
import "quill/dist/quill.snow.css"; //quill编辑器的样式表
import Config from "../config";

import ImageCropper from '@/components/ImageCropper/index.vue'

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "标题",
    dataIndex: "title",
    scopedSlots: {customRender: "title"}
  },{
    title: "备注",
    dataIndex: "remark",
    width: "15%",
    scopedSlots: {customRender: "remark"}
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];
const coursecolumns = [
  {
    title: "id",
    dataIndex: "id",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "标题",
    dataIndex: "title",
    scopedSlots: {customRender: "title"}
  },{
    title: "状态",
    dataIndex: "status",
    width: "15%",
    scopedSlots: {customRender: "status"}
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];
// import * as Util from "../common/util";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  // ["blockquote", "code-block"], //引用，代码块
  [{header: 1}, {header: 2}], // 标题，键值对的形式；1、2表示字体大小
  //[{ list: "ordered" }, { list: "bullet" }], //列表
  //[{ script: "sub" }, { script: "super" }], // 上下标
  [{indent: "-1"}, {indent: "+1"}], // 缩进
  [{direction: "rtl"}], // 文本方向
  [{size: ["small", false, "large", "huge"]}], // 字体大小
  [{header: [1, 2, 3, 4, 5, 6, false]}], //几级标题
  [{color: []}, {background: []}], // 字体颜色，字体背景颜色
  [{font: []}], //字体
  [{align: []}], //对齐方式
  ["clean"], //清除字体样式
  ["image"], //上传图片、上传视频
  // ["image", "video"], //上传图片、上传视频
];
console.log("->>>>>>>>>>>>>>>>>>>>>>",ImageCropper)
export default {
  name: "Dashboard",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ImageCropper,
    quillEditor,
  },

  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  data() {
    return {
      columns,
      coursecolumns,
      cropperOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 200, //默认生成截图框宽度
        autoCropHeight: 200, //默认生成截图框高度
        fixedBox: false, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是圆形
        title: '上传图片', //模态框上显示的标题
      },
      imgType: 'cover', //图片存储在oss上的上级目录名
      imgUrl: '', //上传图片所得到的地址
      content: '', //文本区域内容
      description: '', //文本区域内容
      editorOption: {
        modules: {
          toolbar: {
            container: this.toolbarOptions || toolbarOptions, // 自定义工具栏，与上面定义的toolbarOptions 相对应
            handlers: {
              'image': function (value) {
                if (value) {
                  console.log('你点到我了!')
                  // 获取到antd的upload框选择图片的弹出框事件并触发一次点击
                  document.querySelector('.ant-upload .ant-btn').click()
                } else {
                  //禁用quill自带的image时间
                  this.quill.format('image', false)
                }
              }
            } // 事件重写
          }
        },
        theme: "snow",  //主题
        placeholder: "请输入正文",
      },
      selectedKeys: ['1'],
      expandedKeys: ['1'],
      expandedaddKeys: ['1'],
      data: [],
      coursedata: [],
      treeData: [],
      ClasstreeData: [],
      Class_id: "",
      videodata: [],
      CategoryarticleData: [],
      pagination: {
        pageSize: 15
      },
      coursepagination: {
        pageSize: 15
      },
      replaceFields: {
        children: 'children',
        title: 'title',
        label: 'title',
        key: 'id',
        value: 'key',
      },
      Category_pid: "",
      Category_id: "0",
      Articleinfo: "",
      Category_name: "",
      Category_show: false,
      loading: false,
      mediavisible: false,
      coursevisible: false,
      mediatitle: "",
      pop_Categoryadd: false,
      pop_Categoryedit: false,
      pop_Categorychildrenadd: false,
      pop_Articladd: false,
      pop_Articledit: false,

      add_article: {
        articles_category_id: "",
        author: "ISRP",
        medialink: "",
        introduce: "",
        course_id: "",
        course_title: "",
        cover_img: "",
        title: "",
        type: "1",
        price: "0",
        content: "",
        is_homework: "",
        language: "cn",
      },


      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      previewVisible: false,
      fileList: [],
      previewImage: '',
      cover_img: '',

    };
  },
  created() {
    // 获取第一页内容
    //vue的初始化方法，页面一初始化，就去拿参数
    this.getCategorylist("cn");
    this.category_id = this.$route.query.category_id
    console.log("category_id", this.category_id);
    this.doSearch(1, this.pagination.pageSize);
    // this.getClasslist(1, 1000);
    this.docourseSearch(1, this.pagination.pageSize,"");
  },

  methods: {
    async doSearch(page, limit) {
      this.loading = true;
      this.data = [];
      console.log(Storage.uid)
      console.log(Storage.token)
      let now = Date.now();
      let result = await this.$get("/api/admin/videos/list", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          page: page,
          limit: limit,
          t: now,
        }
      });

      if (result.status === true) {
        // let data = [...this.data];
        // for (let i in result.data.list) {
        //   let info = result.data.list[i];
        //   let offset = (parseInt(page) - 1) * limit + parseInt(i);
        //   // console.log(offset)
        //
        //   data[offset] = {
        //     uid: info.uid,
        //     name: info.name,
        //     group_name: info.group_name,
        //     login_ip: info.login_ip,
        //     login_name: info.login_name,
        //     login_time: moment(info.login_time * 1000).format("YYYY/MM/DD"),
        //     // login_time: info.login_time,
        //     mobile: info.mobile,
        //     nike_name: info.nike_name,
        //     sex: info.sex,
        //     status: info.status,
        //   };
        // }

        let pagination = {...this.pagination};
        pagination.total = result.data.total;
        this.pagination = pagination;
        this.videodata = result.data.list;
        this.loading = false;
      }

      this.loading = false;
    },
    async docourseSearch(page, limit, status) {
      this.loading = true;
      this.coursedata = [];
      console.log(Storage.uid)
      console.log(Storage.token)
      let now = Date.now();
      let result = await this.$get("/api/admin/course/list", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          page: page,
          limit: limit,
          status: status,
          t: now,
        }
      });

      if (result.status === true) {
        // let data = [...this.data];
        // for (let i in result.data.list) {
        //   let info = result.data.list[i];
        //   let offset = (parseInt(page) - 1) * limit + parseInt(i);
        //   // console.log(offset)
        //
        //   data[offset] = {
        //     uid: info.uid,
        //     name: info.name,
        //     group_name: info.group_name,
        //     login_ip: info.login_ip,
        //     login_name: info.login_name,
        //     login_time: moment(info.login_time * 1000).format("YYYY/MM/DD"),
        //     // login_time: info.login_time,
        //     mobile: info.mobile,
        //     nike_name: info.nike_name,
        //     sex: info.sex,
        //     status: info.status,
        //   };
        // }

        let pagination = {...this.coursepagination};
        pagination.total = result.data.total;
        this.coursepagination = pagination;
        this.coursedata = result.data.list;
        this.loading = false;
      }

      this.loading = false;
    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    showDrawer() {
      this.mediavisible = true;
    },
    showcourseDrawer() {
      this.coursevisible = true;
    },
    onClose() {
      this.mediavisible = false;
      this.coursevisible = false;
    },
    onCleal() {
      console.log(this.add_article.medialink);

    },
    delCleal() {
      this.add_article.medialink="";
      this.mediatitle="";
    },
    onClealcourse() {
      console.log(this.add_article.course_id);

    },
    delClealcourse() {
      this.add_article.course_id="";
      this.add_article.course_title="";
    },
    selectmedia(index) {
      console.log(index);
      this.add_article.medialink=index.video_code
      this.mediatitle=index.title
      this.mediavisible = false;
    },
    selectcourse(index) {
      console.log(index);
      this.add_article.course_id=index.id
      this.add_article.course_title=index.title
      this.coursevisible = false;
    },
    callback(key) {
      console.log(key);
    },

    //上传操作结束
    cropClose() {
      console.log('上传操作结束')
    },
    //上传图片成功
    cropSuccess(data) {
      console.log('imgUrldata',data)
      this.imgUrl = this.server_url+data.data.src;
    },
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.content = html
      let  str = text;
      this.description = str.substr(0,150) + '...';
      console.log('description',this.description)
    },
    handleChangeItem(info) {
      console.log('info', info);
      //这一步是获取quilleditor的实例
      let quill = this.$refs.myQuillEditor.quill

      // 获取光标所在位置
      let length = quill.getSelection().index
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.loading = false;
        this.uploadImgUrl =this.server_url+info.file.response.src
        console.log(length)
        // 插入图片  res为服务器返回的图片地址
        quill.insertEmbed(length, 'image', this.uploadImgUrl)
        // 调整光标到最后
        quill.setSelection(length + 1)
        this.$message.success('上传成功')
        //console.log(this.url)
        // this.$message.error('图片插入失败')
      }
    },
    beforeUploaditem(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },

    handleChange(info) {
      this.add_article.cover_image = "";
      console.log("info", info);
      console.log(info.file.status);
      this.fileList = info.fileList;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          this.add_article.cover_image = this.fileList[i].response.src;
          // this.add_article.cover_img = this.fileList[i].response.src;
        }
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.add_article.cover_image);
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    Changetype(e) {
      this.add_article.type = e.target.value
      console.log(this.add_article.type);
    },
    Changelanguage(e) {
      console.log(e.target.value)
      this.add_article.language = e.target.value
      console.log(this.add_article.language);
    },
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize);
    },

    async onChange(pagination) {
      await this.doSearch(
          pagination.current,
          pagination.pageSize
      );
    },
    async oncourseChange(pagination) {
      await this.docourseSearch(
          pagination.current,
          pagination.pageSize
      );
    },
    async onselectpid(value, node) {

      console.log(value);
      this.add_Category.p_id = value;
      console.log(node);

    },
    async onselectClass_id(value, node) {

      console.log(value);
      this.Class_id = value;
      console.log(node);

    },
    async onChanges(e) {
      console.log(e.target.value);
    },


    async getCategorylist(type) {
      this.loading = true;
      let result = await this.$get("/api/admin/articleCategory/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          language: type,
        }
      });

      if (result.status === true) {
        let data = [];

        for (let i in result.data) {
          let info = result.data[i];
          // console.log(i, info)
          data[i] = {
            label: info.name.cn,
            title: info.name.cn,
            key: info.id,
            id: info.id,
            value: info.id,
            p_id: info.p_id,
            children: info.child,
            childrenname: info.child,
          };
        }
        this.treeData = data;
        console.log( this.treeData)
        this.loading = false;
      }

      this.loading = false;
    },



    async article_addok() {
      if (this.add_article.title === "") {
        Modal.warning({
          title: "请输入文章标题"
        });
        return;
      }


      let result = await this.$post("/api/admin/article/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          articles_category_id: this.category_id,
          author: this.add_article.author,
          introduce: this.add_article.introduce,
          cover_image: this.imgUrl,
          title: this.add_article.title,
          type: this.add_article.type,
          price: this.add_article.price,
          course_id: this.add_article.course_id,
          content: JSON.stringify(this.add_article.content),
          short_content: JSON.stringify(this.add_article.content.substr(0,1000)),
          more_info_json: {
            description:this.description,
            medialink:this.add_article.medialink,
            course_title:this.add_article.course_title,
            mediatitle:this.mediatitle,
          },
          is_homework: this.add_article.is_homework,
          language: this.add_article.language,
          is_show:1,
        },
      });

      if (result.status === true) {
        this.data = []
        // await this.getCategoryarticlelist(1, 20, this.Category_id, 0)
        Modal.info({
          title: "添加成功"
        });
        window.vue.$router.push({
          path: "/articleCategoryList",
        });
      }

    },
    async getClasslist(page, limit) {
      this.loading = true;
      let result = await this.$get("/api/admin/course/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          limit: limit,
          page: page,
          status: 1,
        }
      });

      if (result.status === true) {
        let data = [];

        for (let i in result.data.list) {
          let info = result.data.list[i];
          if (info.status === -1) {
            var text = "待发布";
          } else {
            text = "";
          }
          if (info.status === 1) {
            var text1 = "报名中";
          } else {
            text1 = "";
          }
          if (info.status === 2) {
            var text2 = "进行中";
          } else {
            text2 = "";
          }
          if (info.status === 3) {
            var text3 = "已完结";
          } else {
            text3 = "";
          }
          if (info.status === 4) {
            var text4 = "已颁发";
          } else {
            text4 = "";
          }
          if (info.status === 5) {
            var text5 = "已剔除";
          } else {
            text5 = "";
          }
          data[i] = {
            key: info.id,
            id: info.id,
            value: info.id,
            name: info.title + '[' + text + text1 + text2 + text3 + text4 + text5 + ']',
            label: info.title + '[' + text + text1 + text2 + text3 + text4 + text5 + ']',
            title: info.title + '[' + text + text1 + text2 + text3 + text4 + text5 + ']',
            status: info.status,
            created_at: info.created_at,
            updated_at: info.updated_at,
            type: info.type,
          };
        }
        this.ClasstreeData = data;
        console.log(this.ClasstreeData)
        this.loading = false;
      }

      this.loading = false;
    },



  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}

.ant-uploads /deep/ .ant-upload.ant-upload-select-picture-card {
  width: 200px !important;
  height: 200px !important;
}

.ant-uploads /deep/ .ant-upload-list-picture-card .ant-upload-list-item {
  width: 200px !important;
  height: 200px !important;
}

.ant-uploads /deep/ .ant-upload-list-picture-card-container {
  width: 200px !important;
  height: 200px !important;
}

.ant-tabs {
  overflow: unset !important;
}
.note{
  line-height:110%;
  font-size:12px;
  color: #ccc;
  
}
</style>
